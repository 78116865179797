.login {
    & .title {
        text-align: center;

        &--1 {
            margin-bottom: 2vh;
            margin-top: 5vh;

            & img {
                width: 15vw;
            }
        }

        &--2 {
            margin-bottom: 1vh;
        }
    }
}