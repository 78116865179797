.title {
  color: $color-secondary;
  @include font(balboa);

  &--secondary {
    color: $color-focus;
  }

  &--grey {
    color: $color-primary;
  }

  &--light {
    @include font(balboa, light);
  }
  &--bold {
    @include font(balboa, bold);
  }

  &--1 {
    font-size: $title-font-size-1;
  }

  &--2 {
    font-size: $title-font-size-2;
  }

  &--3 {
    font-size: $title-font-size-3;
  }

  &--4 {
    font-size: $title-font-size-4;
  }

  &--5 {
    font-size: $title-font-size-5;
  }

  &--6 {
    font-size: $title-font-size-6;
  }
}
