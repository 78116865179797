.inscription {
  & .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .button {
      align-self: flex-start;
      margin: 0;
    }

    & .title {
      &--1 {
        margin: 0;
        width: 25vw;

        & .image {
          width: 100%;
        }
      }
    }

    & .text,
    & .title--2 {
      margin-bottom: 3vh;
    }
  }
}
