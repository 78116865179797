.form__label {
  color: $color-secondary;
  float: left;
  margin-bottom: 1vh;
  @include font(balboa);

  &--focus {
    color: $color-focus;
  }
}
