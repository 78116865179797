.form__group {
  margin: $margin-form-group;
}

.form__input {
  color: $color-secondary;
  font-size: 3vh;
  background-color: $color-background;
  border-radius: $border-radius;
  border: $border-input;
  padding: .8vh 1vh;
  width: 100%;
  @include font(balboa);

  &:focus {
    outline-offset: 0;
    outline: none;
  }

  &:hover:not(&--focus) {
    border-color: $color-primary;
  }

  &--focus {
    border-color: $color-focus;
  }

  &--big {
    padding: 1vh 2vh;
    height: 7vh;
  }
}

/* Change the background-color in autofill input */
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus,
.form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 7vh $color-shadow inset !important;
}

/* Change text color in autofill input */
.form__input:-webkit-autofill {
  -webkit-text-fill-color: $color-secondary !important;
}
