.details {
    &__scrollable {
        @include transition(transform .2s linear);
        
        &--infos {
            @include transform(translateY(0));
        }
        
        &--episodes {
            @include transform(translateY(-90vh));
        }
    }
    
    &__button {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 10vh;
        justify-content: center;
        width: 100%;

        &:hover {
            & .text { color: $color-focus; }
            & .icon { fill: $color-focus }
        }
        
        & .icon {
            height: 20px;
            width: 20px;
            @include transition(transform .3s linear);
        }
        
        &--previous {
            & .icon {
                @include transform(scale(-1, -1));
            }
        }
    }
    
    // TODO: separate file 
    &__infos {
        align-items: flex-start;
        height: 90vh; //TODO decrease to let appear episodes
        display: flex;
        flex-direction: column;
        margin: 0 15vw;
        justify-content: flex-end;
        
        &__btns {
            display: flex;
            & .text {
                @include font(balboa);
            }
            
            & > *:not(:last-child) {
                margin-right: 1.04vw;
            }

            & .button .icon:not(:last-child) {
                margin-right: .52vw;
            }
        }

        &__progress {

            &__bar {
                align-items: center;
                display: flex;

                &--bar{
                    background: $color-primary;
                    border-radius: 3px;
                    height: 6px;
                    flex-grow: 1;
                    margin-right: .52vw;
                    max-width: 10.41vw;
                    position: relative;
                }

                &--progression {
                    background: $color-secondary;
                    border-radius: 3px;
                    height: 100%;
                    left: 0;
                    min-width: 5%;
                    position: absolute;
                    top: 0;
                }
            }
        }
        
        & .title, &__title-img,
        &__progress,
        &__categories,
        &__synopsis {
            margin-bottom: 1.85vh;
        }

        &__title-img {
            max-height: 30vh;
            max-width: 50Vw;
        }

        &__categories,
        &__meta {
            align-items: center;
            display: flex;

            & > *:not(:last-child) {
                margin-right: 1.04vw;
            }
        }

        &__meta .icon { 
            height:1.85vh;
        }
    }
    
    // TODO: separate file 
    &__series {
        display: flex;
        flex-direction: column;
        height: 90vh;
        flex-basis: 90vh;
        overflow: hidden;

        &--visible {
            .details__series__title {
                display: block;
            }
        }

        &__title {
            display: none;
            margin-bottom: 4vh;
            text-align: center;
        }

        &__hz {
            display: flex;
        }
        
        &__seasons {
            flex-basis: 15vw;
            padding: 0 2vw;
        }
        
        &__episodes {
            flex-basis: 74vw;
            margin-right: 11vw;
            position: relative;
            
            & .arrow {
                width: 100%;
                z-index: 20;
            }
            
            & .episodes {
                height: calc(90vh - 8.3vh);
                overflow-y:  scroll;
                padding: 1vh 0 0 .5vw;
                scroll-behavior: smooth;
                @include hide-scrollbar();
            }
        }
    }
}

// TODO: separate file 
.episode {
    & > span {
        cursor: pointer;
        display: flex;
        margin-bottom: 2.2vh;
        transition: transform 2s linear;
    }
    
    &__thumbnail {
        margin-right: 1vw;
        position: relative;

        &::after {
            @include border-focus($border-radius, $border-active-width, transparent);
            @include transition(border .2s ease-out);  
        }

        & img {
            display: block;
        }

        &, & img {
            border-radius: $border-radius;
            height: $list-item-height;
            width: $list-item-width;
        }
    }

    &--focus &__thumbnail {
        @include transform(scale(1.04)); //TODO variable
        @include transition(transform .2s ease-out);  

        &::after {    
            @include border-focus($border-radius, $border-active-width, $color-focus);
        }
    }


    &__infos {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & .title { margin-bottom: .6vh; }
    }
}

// TODO: separate file 
.season {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2vh;
    padding: 1vh 2.5vw;
    position: relative;
    @include transition(all .2s ease-out);

    & .title, & .text { color: $color-primary }
    
    &:not(.season--active) .text {
        @include transition(all .2s ease-out);
        height: 0;
        font-size: 0;
    }

    &--active {
        justify-content: flex-start;

        & .title, & .text { 
            color: $color-secondary; 
            // font-size: inherit;
            height: inherit;
            @include transform(scale(1.04));
        }
    }

    &::after {
        @include border-focus($border-radius, $border-active-width, transparent);
        @include transition(border .2s ease-out);
    }
    
    &--focus::after {
        @include border-focus($border-radius, $border-active-width, $color-focus);
    }
}