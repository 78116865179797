@mixin arrow-gradient($angle: 0) {
  background: linear-gradient(
    $angle,
    $color-shadow 0%,
    rgba($color-shadow, 0.5) 33%,
    $transparent 100%
  );

  &:hover,
  &:active {
    background: linear-gradient(
      $angle,
      $color-shadow 0%,
      rgba($color-shadow, 0.5) 67%,
      $transparent 100%
    );
  }
}

.arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;

  &:hover .icon,
  &:active .icon {
    fill: $color-focus;
  }

  &--gradient {
    &.arrow--left {
      @include arrow-gradient(90deg);
    }

    &.arrow--right {
      @include arrow-gradient(270deg);
    }

    &.arrow--up {
      @include arrow-gradient(180deg);
    }

    &.arrow--down {
      @include arrow-gradient(0);
    }
  }

  &--right {
    right: 0;
  }

  &--left {
    left: 0;
  }

  &--up {
    top: 0;
  }

  &--down {
    bottom: 0;
  }

  &--hide {
    display: none;
  }
}
