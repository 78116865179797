.image {
  @include font(balboa);

  &--fullscreen {
    position: absolute;
    top: 0;
    height: auto;
    width: 100vw;
    color: $color-secondary;
    font-size: 12vh;
  }
}
