.sidebar {
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
  left: 0;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  @include transition(width 0.2s, background-color 0.2s);
  width: 100%;
  z-index: $z-index-navbar;

  & .menu__close {
    display: none;
  }

  &__logo {
    padding: 8vh 2vw;
    width: 20vw;
    flex-basis: 20vh;

    & img {
      width: 100%;
    }
  }

  &:not(.sidebar--hide) {
    background: $sidebar-background;
  }

  &--hide {
    box-shadow: none;
    padding-top: 23vh;
    width: $width-sidebar-close;

    &:hover {
      background-color: $dove-gray;
    }

    & .menu { width: 100%;}
    & .menu__open,
    & .sidebar__logo {
      display: none;
      @include transition(display 0.5s ease-in);
    }

    & .menu__close {
      display: flex;
    }
  }
}
