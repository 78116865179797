.button {
  border: $button-border;
  color: $color-secondary;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $button-margin-bottom;
  @include font(balboa);

  &:hover {
    cursor: pointer;
  }

  &--medium {
    padding: $button-padding-medium;
  }

  &--big {
    padding: $button-padding-big;
  }

  &--giant {
    // TODO: replace by text component in js
    // font-size: $button-font-size-giant;
    padding: $button-padding-giant;
  }

  &--round {
    align-items: center;
    border-radius: calc(2.9vh + 1.9vh) !important;
    display: flex;
    justify-content: center;
    padding: $button-padding-round;
      
    & .icon {
      // height: 2.9vh;
      // width: 2.9vh;
    }
  }

  &--focus {
    background-color: $color-focus !important;
    border-color: $color-focus !important;
  }

  &--block-full {
    width: 100%;
  }

  &--block-half {
    width: 50%;
  }

  &--style-outline {
    background-color: transparent;
    border-radius: $button-border-radius;
  }

  &--style-plain {
    background-color: $color-primary;
    border: $button-border-plain;
    margin: 0;
  }
}
