.wrapper {
  z-index: $z-index-list-arrow;
  width: $width-fullscreen;
  padding: $padding-wrapper;

  &--sticky-bottom {
    position: absolute;
    bottom: $absolute-bottom;
  }

  &--padding-none {
    padding: $padding-none;
  }

  &--centered {
    margin: 6vh auto;
    padding: 0 3vw;
    width: 50%;
  }

  &--size-half {
    width: 50%;
  }

  &--overlay {
    background: $mine-shaft-transparent;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;

    &-gl {
      background: linear-gradient(90deg, $color-shadow 10%, $transparent 100%);
    }

    &-gb {
      background: linear-gradient(0deg,rgba(0, 0, 0, 0.75) 0%,rgba(0, 0, 0, 0.55) 100%);
    }
  }
}
