.player {
  height: 100vh;
  width: 100vw;
   
  &__controls {
    height: 100%;
    width: 100%;

    &__button {
      cursor: pointer;
    }

    &__overlay {
      background-color: transparent !important;
      background: linear-gradient(180deg, 
        rgba(0,0,0,0.8) 0%, 
        rgba(0,0,0,0.1) 33%, 
        rgba(0,0,0,0.1) 66%, 
        rgba(0,0,0,0.8) 100%);
      height: 100% !important;
      position: relative;
    }  

    &__top-left, 
    &__top-center,
    &__bottom {
      position: absolute;
    }

    &__top-left {
      display: flex;
      left: 2vh;
      top: 2vh;

      & > *:not(:last-child) {
        margin-right: 1.5vw;
      }
    }
    
    &__top-center {
      align-items: center;
      display: flex;
      flex-direction: column;
      left: 50%;
      top: 2vh;
      transform: translateX(-50%);

      & .title { margin-bottom: .1vw; }
    }

    &__bottom {
      bottom: 0;
      display: flex;
      padding: 2vh 2vw 5vh;
      width: 100%;

      &__play {
        margin-right: 1vw;
      }

      &__progress {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        // padding: calc(1vw + 0.7vh) 1vh 1vh;

        &__bar {
          background: $color-primary;
          border-radius: .3vh;
          cursor: pointer;
          height: .6vh;
          position: relative;
          flex-grow: 1;
        }

        &__marker {
          background: $color-secondary;
          border-radius: 50%;
          height: 1.2vh;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 1.2vh;

        }

        &__time {
          left: 0;
          position: absolute;
          top: 110%;
        }
        
        &__time-total {
          position: absolute;
          right: 0;
          top: 110%;
        }
      }
    }
  }
}

.control {
  padding: 1vh;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  & .text {
    visibility: hidden;
    position: absolute;
    top: 80%;
    text-align: center;
    width: 200%;
  }

  &--focus {
    filter: drop-shadow(0 0 8px #b7b7b7);
    transform: scale(1.04);

    & .text {
      visibility: visible;
    }
  }
}