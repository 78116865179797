.list-item {
  align-items: center;
  background-color: $mine-shaft;
  border-radius: $border-radius;
  display: inline-flex;
  justify-content: center;
  margin-right: $list-item-margin-right;
  position: relative;

  &::after {
    @include border-focus($border-radius, $border-active-width, transparent);
    @include transition(border-color .2s ease-out, transform .2s ease-in);
  } 

  & img {
    @include transition(transform .2s ease-out);
  }

  &--active {
    cursor: pointer;
    
    & img {
      display: block;
      @include transform(scale(1.04)); //TODO variable
      transform-origin: center;
    }

    &::after {    
      @include border-focus($border-radius, $border-active-width, $color-focus, 1.04);
    }
  }

  &--thumbnail {
    &:not(.list-item--xl) {
      &,
      & img,
      & .loader--wrapper {
        height: $list-item-height;
        width: $list-item-width;
      }
    }
  
    &.list-item--xl {
      &,
      & img,
      & .loader--wrapper {
        height: 35vh;
        width: calc(35vh * 16 / 9);
      }
    }  
  }

  &--square {
    &,
    & img,
    & .loader--wrapper {
      height: $list-item-width;
    }  
  }

  &--poster {
    &:not(.list-item--xs, .list-item--xl) {
      &,
      & img,
      & .loader--wrapper {
        height: 45vh;
        width: calc(45vh * 3 / 4);
      }
    }

    &.list-item--xs {
      &,
      & img,
      & .loader--wrapper {
        height: 35vh;
        width: calc(35vh * 3 / 4);
      }
    }

    &.list-item--xxs {
      &,
      & img,
      & .loader--wrapper {
        height: 30vh;
        width: calc(30vh * 3 / 4);
      }
    }

    &.list-item--xl {
      &,
      & img,
      & .loader--wrapper {
        height: calc($list-item-width * 4 / 3);
      }
    }
  }

  &--posterlong {
    &:not(.list-item--xs, .list-item--xl, .list-item--xxs) {
      &,
      & img,
      & .loader--wrapper {
        height: 50vh;
        width: calc(45vh * 9 / 16);
      }
    }

    &.list-item--xs {
      &,
      & img,
      & .loader--wrapper {
        height: 45vh;
        width: calc(40vh * 9 / 16);
      }
    }

    &.list-item--xl {
      &,
      & img,
      & .loader--wrapper {
        height: calc($list-item-width * 4 / 3);
      }
    }

    &.list-item--xxs {
      &,
      & img,
      & .loader--wrapper {
        height: 40vh;
        width: calc(40vh * 9 / 16);
      }
    }
  }


  & .loader--wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  & img {
    object-fit: cover;
    border-radius: $border-radius;
  }

  & .text {
    position: absolute;
    line-height: 1.5;
    text-align: center;
    transform-origin: center;
    z-index: 30;
  }

  & .loader--wrapper {
    position: absolute;
  }

  &__progress-bar {
    background: $white;
    border-radius: .5vh;
    top: calc(100% + 2 * $border-active-width);//.4vw = border-active width
    height: .6vh;
    position: absolute;
    width: 100%;

    &--progression {
      background: $california;
      border-radius: .5vh;
      height: .6vh;
      left: 0;
      position: absolute;
      top: 0;
      z-index: 10;
    }
  }
}
