@import "colors";

//  Colors
$color-primary: $nobel;
$color-secondary: $white;
$color-focus: $california;
$color-error: $flamingo;
$color-success: $flamingo;
$color-shadow: $cod-gray;
$color-background: $cod-gray;

//Z-INDEX
$z-index-navbar: 100;
$z-index-navbar-overlay: 99;
$z-index-list-arrow: 98;
$z-index-front: 99;
$z-index-listItem: 97;

//BORDER
$border-input: .1vw solid $color-secondary;
$border-active-width: .16vw;
$border-active: $border-active-width solid $color-focus;
$border-radius: .52vw;


// Padding
$padding-none: 0;
$padding-bottom-pages: 4vh;

$padding-wrapper: 4vh 0 0 2vw;

// Margin
$margin-bottom-menu-item-separator: 7vh;
$margin-form-group: 2vh;

// Absolute values
$absolute-bottom: 0;

// Width
$width-fullscreen: 100%;
$width-sidebar-close: 6vw;
$width-sidebar-open: 20vw;

// Height
$height-search-input: 15vh;
$height-menu-item: 10vh;

// Shadow
$shadow-text-thumbnail: 0 0 1.2vw $color-shadow;

////////////////////////////////////////////////////////////////
/////////////////////////// COMPONENTS /////////////////////////
////////////////////////////////////////////////////////////////
// Button
$button-border-width: 0.16vw;
$button-border: $button-border-width solid $color-focus;
$button-border-plain: $button-border-width solid $color-primary;
$button-border-radius: .52vw;
// $button-font-size-big: 3.5vh;
// $button-font-size-giant: 4vh;
$button-margin-bottom: 3vh;
$button-padding-medium: 1vh 1vw;
$button-padding-big: 1.94vh 2.66vw;
$button-padding-giant: 1.3vh 0;
$button-padding-round: 1.9vh;
$button-border-round: $button-border-width solid $color-secondary;

// List
$list-padding: 2vh 1vw 6vh;
$list-hz-padding-right: 1vw;

// ListItem
$list-item-height: 20vh;
$list-item-width: calc($list-item-height * 16 / 9);
$list-item-margin-right: 1vw;

// Overlay
$overlay-background-default: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(43, 43, 43, 0.4) 50%, rgb(0, 0, 0) 100%) 0% 0% no-repeat padding-box;

// Sidebar
$sidebar-background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.85) 18%, rgba(0,0,0,0.4) 33%, rgba(0,0,0,0.4) 100%);

// Text
$text-font-size-xxs: 1.4vh;
$text-font-size-xs: 1.6vh;
$text-font-size-small: 1.85vh;
$text-font-size: 2.41vh;
$text-font-size-large: 3.5vh;
$text-line-height: 2.8vh;
$text-margin-bottom: 1vh;

// Title
$title-font-size-1: 4.3vh;
$title-font-size-2: 3.3vh;
$title-font-size-3: 2.4vh;
$title-font-size-4: 2.1vh;
$title-font-size-5: 2.1vh;
$title-font-size-6: 2.1vh;

/**
 * Details 
 */
//  Episode
//  Season
$season-border-focus-width: .2vw ;
$season-border-focus: $season-border-focus-width solid $color-focus;
$season-border-radius-focus: .3vw;
//  Series